import { createRef, FC, useCallback, useEffect, useRef, useState } from 'react'
import styles from './Layout.module.scss'

const Layout: FC = ({ children }) => {
  const canvas = createRef<HTMLCanvasElement>()
  const [context, setContext] = useState<CanvasRenderingContext2D | null>()

  var col = useCallback(
    (x: number, y: number, r: number, g: number, b: number) => {
      if (!context) return
      context.fillStyle = 'rgb(' + r + ',' + g + ',' + b + ')'
      context.fillRect(x, y, 1, 1)
    },
    [context]
  )

  var R = function (x: number, y: number, t: number) {
    return Math.floor(200 + 60 * Math.cos((x * x - y * y) / 420 + t))
  }

  var G = function (x: number, y: number, t: number) {
    return Math.floor(200 + 60 * Math.sin((x * x * Math.cos(t / 4) + y * y * Math.sin(t / 3)) / 420))
  }

  var B = function (x: number, y: number, t: number) {
    return Math.floor(200 + 60 * Math.sin(5 * Math.sin(t / 9) + ((x - 100) * (x - 100) + (y - 100) * (y - 100)) / 1100))
  }

  var t = useRef(1000)
  // const [t, setT] = useState(1000)

  const run = useCallback(() => {
    for (var x: number = 0; x <= 35; x++) {
      for (var y: number = 0; y <= 35; y++) {
        col(x, y, R(x, y, t.current), G(x, y, t.current), B(x, y, t.current))
      }
    }
    t.current = t.current + 0.03
    window.requestAnimationFrame(run)
  }, [col])

  useEffect(() => {
    if (!window) return
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
    if (canvas.current) {
      setContext(canvas.current.getContext('2d'))
      if (!mediaQuery || mediaQuery.matches) {
      } else {
        run()
      }
    }
    return () => {}
  }, [canvas, run])

  return (
    <>
      <canvas className={styles.Canvas} ref={canvas} width='32' height='32' aria-label='Gradient background' />
      <section className={styles.Layout}>{children}</section>
    </>
  )
}
export { Layout }
