import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styles from './App.module.scss'
import { Logo } from './components/Logo'

const App = () => {
  const [currentHeadline, setCurrentHeadline] = useState<number>(0)
  const headlines = [
    'web\ndevelopment\nfor your\nbusiness',
    'logo\ncreation\nfor your\nbrand',
    'ui\ndesign\nfor your\napp',
  ]
  const duration = 6

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHeadline((v) => (v + 1 === headlines.length ? 0 : v + 1))
    }, duration * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [headlines.length])

  const headline = headlines[currentHeadline].split('\n')

  return (
    <>
      <header className={styles.App__Header}>
        <h1 className={styles.Headline}>
          <AnimatePresence initial={false}>
            {headline.map((w, i) => (
              <motion.span
                style={{ '--index': i } as any}
                key={`w-${w}-${i}`}
                className={styles.Headline__Word}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.6, ease: 'easeInOut' }}
              >
                <motion.span
                  key={`w-c-${w}-${i}`}
                  className={styles.Headline__Word__Content}
                  initial={{ y: -60 }}
                  animate={{ y: 0 }}
                  exit={{ y: 60 }}
                  transition={{ duration: 0.6, ease: 'easeInOut' }}
                >
                  {w}
                </motion.span>
              </motion.span>
            ))}
          </AnimatePresence>
        </h1>
      </header>
      <footer className={styles.App__Footer}>
        <aside className={styles.Logo}>
          <Logo />
          <p className={styles.Logo__Caption}>Beyond the perfection of craft</p>
        </aside>
        <a
          href='mailto:inquiry@unmatched.work?subject=UNMATCHED%20WEB%20INQUIRY&body=Hi%2C%0D%0AI%20am%20(full%20name)%20and%20I%20live%20in%20(country%2Fcity).%0D%0AI%20have%20a%20(business%20name%20%26%20type).%0D%0AI%20want%20(to%20develop%20a%20web%20app%2Fa%20logo%20design%2Fa%20UI%20design).%0D%0A%0D%0A(Please%20provide%20as%20many%20details%20as%20possible)'
          className={styles.Link}
        >
          Make an inquiry
        </a>
      </footer>
    </>
  )
}

export default App
